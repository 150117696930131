export type UIFriendlyMessageType = 'WARNING' | 'ERROR';

export interface IUIFriendlyMessageJSON {
    uiFriendlyMessageType: UIFriendlyMessageType;
    title: string;
    body: string;
}

export class UIFriendlyMessage {
    public readonly uiFriendlyMessageType: UIFriendlyMessageType;
    public readonly title: string;
    public readonly body: string;

    public get typeIcon() {
        switch (this.uiFriendlyMessageType) {
            case 'WARNING':
                return '';
            case 'ERROR':
                return '';
        }
    }

    public get typeColor() {
        switch (this.uiFriendlyMessageType) {
            case 'WARNING':
                return 'text-warning';
            case 'ERROR':
                return 'text-danger';
        }
    }

    constructor(uiFriendlyMessageType: UIFriendlyMessageType, title: string, body: string) {
        this.uiFriendlyMessageType = uiFriendlyMessageType;
        this.title = title;
        this.body = body;
    }

    public toJSON(): IUIFriendlyMessageJSON {
        return Object.assign({}, this);
    }
}
